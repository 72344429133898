import React, { useState, useEffect, FC } from 'react'
import { DemographicsSurvey } from './DemographicsSurvey'
import { LinearProgress } from '@mui/material'
import { AUT } from './AUT'
import { BigFive } from './BigFive'
import './App.css'
import { EndScreen } from './EndScreen'
import {
  CategoryNames,
  categoryOrder,
  shortCategoryNames,
} from './data/CreativeAchievementsData'
import { Welcome } from './Welcome'
import { BigFiveData } from './data/BigFiveData'
import { CollaboUse } from './CollaboUse'
import { AUTExperience } from './AUTExperience'
import { CollaboUseSuggestion } from './data/CollaboUseData'

export const numberOfPages = 6

export interface AUTAnswer {
  answer: string
  time: Date
  secondsExpired: number
  selected: boolean
}

export interface SurveyState {
  id?: string

  age?: number
  gender?: number
  degree?: number
  englishLevel?: number
  continent?: number

  autStart?: Date | null
  autAnswers: AUTAnswer[]
  autExperience: string

  collaboUseResult: CollaboUseSuggestion[]

  bigFive: BigFiveData

  lieItemDemographics: boolean
}

function ProgressBar({ page }: { page: number }) {
  return (
    <>
      <LinearProgress
        style={{ height: '20px' }}
        variant="determinate"
        value={(page / numberOfPages) * 100}
      />
      <p className="pagesLeft">
        {numberOfPages - page} page
        {numberOfPages - page != 1 ? 's' : ''} to go
      </p>
    </>
  )
}

export const App: FC = () => {
  const [page, setPage] = useState<number>(() => {
    const page = localStorage.getItem('page')
    if (!page) return 0
    try {
      return parseInt(page)
    } catch {
      return 0
    }
  })

  const [surveyState, setSurveyState] = useState<SurveyState>(() => {
    //TODO What does this do exactly?
    const creativeAchievement = {} as Record<string, boolean[]>
    categoryOrder.map((category) => {
      const shortName: string =
        shortCategoryNames[category as keyof CategoryNames]
      creativeAchievement[shortName] = new Array(8).fill(false)
    })

    const fallback = {
      id: null,
      //Demographics
      age: null,
      gender: null,
      degree: null,
      englishLevel: null,
      continent: null,
      //AUT
      autStart: null,
      autExperience: '',
      autAnswers: [],
      //CollaboUse
      collaboUseResult: [],
      //B5
      bigFive: new BigFiveData(),
      //Other
      lieItemDemographics: false,
    }

    const data = localStorage.getItem('surveyState')
    if (!data) return fallback
    try {
      return JSON.parse(data)
    } catch {
      return fallback
    }
  })

  function advance(): void {
    setPage((page) => page + 1)
    window.scrollTo(0, 0)
    window.localStorage.setItem('page', page.toString())
    window.localStorage.setItem('surveyState', JSON.stringify(surveyState))
  }
  const handleBeforeunload = (event: BeforeUnloadEvent) => {
    if (page === numberOfPages) return
    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload, true)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload, true)
    }
  })

  return (
    <>
      <ProgressBar page={page} />
      {page === 0 && (
        <Welcome
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 1 && (
        <AUT state={surveyState} setState={setSurveyState} advance={advance} />
      )}
      {page === 2 && (
        <CollaboUse
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 3 && (
        <AUTExperience
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
        />
      )}
      {page === 4 && (
        <DemographicsSurvey
          state={surveyState}
          setState={setSurveyState}
          advance={advance}
          page={page}
        />
      )}
      {page === 5 && (
        <BigFive
          state={surveyState}
          setState={setSurveyState}
          page={page}
          advance={advance}
        />
      )}
      {page === 6 && <EndScreen state={surveyState} />}
    </>
  )
}
