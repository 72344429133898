import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { SurveyState } from './App'
import { v4 as uuid } from 'uuid'
import { isInTestMode } from './Welcome'
import { TestState, timerString } from './utils/utils'
import {
  CollaboUseSuggestion,
  CollaboUseSuggestionItem,
  CollaboUseTestDuration,
  CollaboUseDuration,
  suggestionGames,
  suggestionMovie,
  itemsMovie,
  itemsGames,
  promptGames,
  promptMovie,
  availableItemsGames,
  availableItemsMovie,
} from './data/CollaboUseData'
import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { withStyles } from '@mui/styles'

export const CollaboUse: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const collaboUseLength = isInTestMode
    ? CollaboUseTestDuration
    : CollaboUseDuration

  const urlParams = new URLSearchParams(window.location.search)
  const task = urlParams.get('task') ?? 'games'

  const prompt = task == 'games' ? promptGames : promptMovie
  const availableItems =
    task == 'games' ? availableItemsGames : availableItemsMovie

  const [currentStatus, setCurrentStatus] = useState(TestState.before)
  const [suggestions, setSuggestions] = useState([
    { title: '', items: [], id: uuid(), selected: false },
  ] as CollaboUseSuggestion[])

  const [timeLeft, setTimeLeft] = useState(collaboUseLength)
  const [startTime, setStartTime] = useState(null as null | number)

  const DarkerDisabledTextField = withStyles({
    root: {
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.8)', // (default alpha is 0.38)
      },
    },
  })(TextField)

  function onTimerEnded() {
    const newState = { ...state, collaboUseResult: suggestions }
    setState(newState)
    window.localStorage.setItem('surveyState', JSON.stringify(newState))
    setCurrentStatus(TestState.after)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (startTime !== null) {
        setTimeLeft(collaboUseLength - (Date.now() - startTime))
      }
      if (startTime && timeLeft <= 0) {
        setStartTime(null)
        clearInterval(interval)
        onTimerEnded()
      }
    }, 100)
    return () => clearInterval(interval)
  }, [startTime, timeLeft, setTimeLeft])

  function addSuggestion() {
    const s = {
      title: '',
      items: [],
      id: uuid(),
      selected: false,
    }
    setSuggestions((oldSuggestions) => [...oldSuggestions, s])
  }
  function editSuggestionTitle(id: string, title: string) {
    setSuggestions((oldSuggestions) =>
      oldSuggestions.map((s) => (s.id === id ? { ...s, title } : s)),
    )
  }
  function addItem(suggestionId: string): CollaboUseSuggestionItem {
    const item = {
      item: null,
      id: uuid(),
    } as CollaboUseSuggestionItem
    setSuggestions((oldSuggestions) =>
      oldSuggestions.map((s) =>
        s.id === suggestionId ? { ...s, items: [...s.items, item] } : s,
      ),
    )
    return item
  }
  function editItem(suggestionId: string, itemId: string, value: string) {
    const item = {
      item: value,
      id: itemId,
    } as CollaboUseSuggestionItem
    setSuggestions((oldSuggestions) =>
      oldSuggestions.map((s) =>
        s.id === suggestionId
          ? { ...s, items: s.items.map((i) => (i.id === item.id ? item : i)) }
          : s,
      ),
    )
  }
  function buildExample(task: string) {
    return (
      <div className="suggestion">
        <FormControl>
          <DarkerDisabledTextField
            label="Suggestion"
            style={{ marginRight: '0.5rem' }}
            variant="outlined"
            className="suggestion-inputs"
            value={task == 'games' ? suggestionGames : suggestionMovie}
            disabled={true}
          />
        </FormControl>
        {(task === 'games' ? itemsGames : itemsMovie).map((item, index) => {
          return (
            <FormControl key={index}>
              <DarkerDisabledTextField
                variant="outlined"
                style={{ marginRight: '0.5rem' }}
                className="item"
                label="Item"
                select
                value={item}
                disabled={true}
              >
                <MenuItem value={item}>{item}</MenuItem>
              </DarkerDisabledTextField>
            </FormControl>
          )
        })}
      </div>
    )
  }

  function showTask() {
    return (
      <>
        <h2
          style={{
            color: timeLeft <= 10 ? 'crimson' : 'black',
            marginTop: '0',
          }}
        >
          {timerString(timeLeft)}
        </h2>
        <h3>Think up ideas: What could be "{prompt}"</h3>

        <div className="items-list-container">
          <p className="item-description">
            Use and combine items from this list:
          </p>
          <div className="items-list">
            {availableItems.map((item) => {
              return <p key={item}>{item}</p>
            })}
          </div>
        </div>

        <div className="flex">
          <p className="example">Example:</p>
          <div className="suggestion-box">
            {buildExample(task)}
            {suggestions.map((suggestion) => {
              return (
                <div className="suggestion" key={suggestion.id}>
                  <TextField
                    style={{ marginRight: '0.5rem' }}
                    variant="outlined"
                    placeholder="Your Suggestion"
                    className="suggestion-inputs"
                    value={suggestion.title}
                    onChange={(event) =>
                      editSuggestionTitle(suggestion.id, event.target.value)
                    }
                  />
                  {suggestion.items.map((item) => (
                    <Select
                      variant="outlined"
                      className="item"
                      key={item.id}
                      value={item.item ?? ''}
                      onChange={(event) =>
                        editItem(
                          suggestion.id,
                          item.id,
                          event.target.value as string,
                        )
                      }
                    >
                      {availableItems.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  ))}
                  <FormControl>
                    <Select
                      variant="outlined"
                      className="item"
                      value=""
                      displayEmpty
                      style={{ color: '#999' }}
                      onChange={(event) => {
                        const item = addItem(suggestion.id)
                        editItem(
                          suggestion.id,
                          item.id,
                          event.target.value as string,
                        )
                      }}
                    >
                      {[
                        <MenuItem key="empty" value="">
                          <em>Item</em>
                        </MenuItem>,
                        ...availableItems.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )),
                      ]}
                    </Select>
                  </FormControl>
                </div>
              )
            })}
          </div>
        </div>
        <Button
          variant="contained"
          className="suggestion-button"
          onClick={addSuggestion}
        >
          Add Suggestion
        </Button>
      </>
    )
  }

  function showIntro() {
    return (
      <div className={'formContainer'}>
        <p>
          On the next page, you receive a design challenge and your task is to
          come up with many different solutions in 3 minutes time.
          <br></br>
          <br></br>
          For instance, your job can be to create “tools to survive in the
          wilderness”. As building material, you have ten objects. Use and
          combine them to create your solutions. For instance, when you have a
          blanket and sticks, you can combine them to build a tent to survive in
          the wilderness.
          <br></br>
          <br></br>
          Each of the ten objects can be re-used as often as you like.
          <br></br>
          <br></br>
          Try to produce as many, diverse, and original solutions as you can in
          the given time!
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentStatus(TestState.during)
            setStartTime(Date.now() + 1000)
          }}
          className="startButton"
        >
          Start
        </Button>
      </div>
    )
  }
  function showEnd() {
    const enoughAnswers = suggestions.length >= 2
    const error = suggestions.filter((s) => s.selected).length !== 2

    function updateSelected(suggestion: CollaboUseSuggestion) {
      const newSuggestions = suggestions.map((s) =>
        s.id === suggestion.id
          ? {
              ...s,
              selected: !s.selected,
            }
          : s,
      )
      setSuggestions(newSuggestions)
      const newState = { ...state, collaboUseResult: newSuggestions }
      setState(newState)
      window.localStorage.setItem('surveyState', JSON.stringify(newState))
    }

    return (
      <div className={'formContainer'}>
        <p>
          Time's up!
          {enoughAnswers
            ? ' Please select the two ideas from your list that you find most original.'
            : ' Click next to proceed with the survey.'}
        </p>

        {enoughAnswers && (
          <FormControl
            sx={{ m: 3 }}
            component="fieldset"
            variant="standard"
            error={error}
          >
            <FormLabel component="legend">Select Two</FormLabel>
            <FormGroup>
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.id}
                  className="row"
                  style={{ userSelect: 'none' }}
                  onClick={() => updateSelected(suggestion)}
                >
                  <Checkbox checked={suggestion.selected} />
                  <b>{suggestion.title}&nbsp;</b>
                  {suggestion.items.map((item, index) => (
                    <p key={item.id}>
                      {item.item}
                      {index + 1 !== suggestion.items.length ? ',' : ''}
                      &nbsp;
                    </p>
                  ))}
                </div>
              ))}
            </FormGroup>
          </FormControl>
        )}

        <Button
          variant="contained"
          color="primary"
          disabled={enoughAnswers && error}
          className={'submitButton'}
          onClick={advance}
        >
          Next
        </Button>
      </div>
    )
  }

  function content() {
    switch (currentStatus) {
      case TestState.before: {
        return showIntro()
      }
      case TestState.during: {
        return showTask()
      }
      case TestState.after: {
        return showEnd()
      }
      default: {
        return showIntro()
      }
    }
  }

  return (
    <>
      <h1>Survey - Association Game 2</h1>
      {content()}
    </>
  )
}
