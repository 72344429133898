import React, { FC, useEffect, useState } from 'react'
import { SurveyState } from './App'
//import { isInTestMode } from './Welcome'

export const EndScreen: FC<{
  state: SurveyState
}> = ({ state }) => {
  const [dataSent, setDataSent] = useState(false)

  function sendDataToServer() {
    //if (isInTestMode) return
    setDataSent(true)
    const http = new XMLHttpRequest()
    const url = 'https://survey.corinnaj.me/upload.php'
    http.open('POST', url)
    http.setRequestHeader('Content-type', 'application/json')
    const stateToSend: Record<string, unknown> = {
      ...state,
      folder: window.location.pathname.substring(1),
    }
    delete stateToSend.page
    http.send(JSON.stringify(stateToSend))
  }

  useEffect(() => {
    if (dataSent) return
    window.localStorage.setItem('surveyState', JSON.stringify(state))
    sendDataToServer()
  }, [dataSent])

  return (
    <div className="formContainer">
      <h1>Congratulations!</h1>
      <p>
        You have reached the end of the survey, thank you for taking part in
        this study.<br></br> In order to receive your payment, please copy the
        code displayed below and send it to corinna.jaschek@hpi.de.
      </p>
      <h4>{state.id}</h4>
    </div>
  )
}
