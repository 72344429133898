export interface CollaboUseSuggestion {
  title: string
  items: CollaboUseSuggestionItem[]
  id: string
  selected: boolean
}
export interface CollaboUseSuggestionItem {
  item: null | string
  id: string
}

export const promptGames = 'entertaining games for a group of friends'
export const promptMovie = 'decorating items for a science fiction movie'
export const availableItemsGames = [
  'Apple',
  'Hammer',
  'Jacket',
  'Hat',
  'Ring',
  'Table',
  'Rope',
  'Ketchup',
  'Needle',
  'Mirror',
]
export const availableItemsMovie = [
  'DVD',
  'Nail',
  'Ink',
  'Wire',
  'Chair',
  'Tire',
  'Shoe',
  'Blanket',
  'Screwdriver',
  'Orange',
]

export const CollaboUseDuration = 3 * 60 * 1000
export const CollaboUseTestDuration = 11 * 1000

export const suggestionGames = 'Throw "ball" into hat'
export const suggestionMovie = 'Alien costume'
export const itemsGames = ['Apple', 'Hat']
export const itemsMovie = ['DVD', 'DVD', 'Wire']
