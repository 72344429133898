import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { SurveyState } from './App'
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

export const AUTExperience: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const [unfilledVariables, setUnfilledVariables] = useState(true)

  function updateState(newState: SurveyState) {
    setState(newState)
    setUnfilledVariables(
      newState.autExperience == null || newState.autExperience === '',
    )
  }

  return (
    <>
      <h1>Survey - Association Games</h1>
      <div className={'formContainer'}>
        <p>
          Now you have played two association games, first finding uncommon uses
          for a brick and then designing games for a group of friends. Have you
          ever worked on a similar task before, where you should find many and
          unconventional solutions in a challenge like this?
        </p>

        <FormControl component="fieldset" className={'formControl'}>
          <RadioGroup
            aria-label="aut_experience"
            name="aut_experience"
            value={state.autExperience}
            onChange={(event) =>
              updateState({ ...state, autExperience: event.target.value })
            }
          >
            <FormControlLabel
              value="unsure"
              control={<Radio />}
              label="I am not sure"
            />
            <FormControlLabel value="no" control={<Radio />} label="No" />
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          </RadioGroup>
        </FormControl>

        {unfilledVariables && (
          <p className="buttonHelperText">
            All fields must be filled before proceeding
          </p>
        )}
        <Button
          variant="contained"
          color="primary"
          className={'submitButton'}
          disabled={unfilledVariables}
          onClick={advance}
        >
          Next
        </Button>
      </div>
    </>
  )
}
